import React from 'react';
import { InternalPage } from '../style/InternalPage';

const Page = () => (
	<InternalPage>
		<p>
			<strong>DISCLAIMER</strong>
		</p>
		<p>INFORMATIONAL PURPOSES ONLY</p>
		<p>
			By using this website, you agree to the terms and conditions set forth in
			this disclaimer, and you acknowledge reading them. We provide the
			materials on this website for general information purposes only, and these
			materials do not constitute legal or other professional advice. We do not
			accept any responsibility for any loss that may arise from reliance on the
			information contained on this site. No visitor to this site should act or
			refrain from acting based on information contained on this site without
			seeking advice of counsel.{' '}
		</p>
		<p>NO ATTORNEY-CLIENT RELATIONSHIP</p>
		<p>
			Neither your sending of an e-mail to an attorney, nor your receipt of
			information from this website, creates an attorney-client relationship. An
			attorney-client relationship may be formed only after execution of an
			engagement letter. We do not intend this website, or information contained
			in or transmitted by this website, to constitute a solicitation.
			ATTORNEY-CLIENT PRIVILEGE; CONFIDENTIAL INFORMATION We do want to hear
			from you by e-mail. But unless you are already a client of ours and have
			completed the engagement letter process, no attorney-client privilege
			exists and any information you e-mail to us will not constitute
			confidential information. Always exercise discretion before sending
			sensitive or confidential information by e-mail.
		</p>
	</InternalPage>
);

export default Page;
